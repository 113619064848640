import ReactGodot from "../react-godot/GodotReact.tsx"






function SlyBalabama() {
    const GODOT_CONFIG = {"args":[],"canvasResizePolicy": 2,"executable":"/games_content/SlyBalabama/SlyBalabama","experimentalVK":false,"fileSizes":{"SlyBalabama.pck":5218976,"SlyBalabama.wasm":13790961},"focusCanvas":true,"gdnativeLibs":[]};

    return (
    <div>
        <ReactGodot key={"godotplay"} godotConfig={GODOT_CONFIG}  resize={true} />
    </div>
    )
}


export default SlyBalabama