import React, { useEffect, useState } from "react";

import UniversalServerService from "../../services/UniversalServerService";
import {SuccessMessage} from "../../constants";

import {useParams, useNavigate} from "react-router-dom"








function DayReplays() {
    let { scope, gameType, day } = useParams();

    const [dayFiles, setDayFiles] = useState([]);
    const navigate = useNavigate();


    useEffect(()=> {
        UniversalServerService.getDayReplays(scope, gameType, day).then((response)=>{
            if (response.data.Success == SuccessMessage) {
                setDayFiles(response.data.ReplayFiles.DayFiles)
            }
        })
    }, [])

    const fileClick = (fileName)=>{
        var signature = "replay|"+scope+"|"+gameType+"|"+day+"|"+fileName
        localStorage.setItem('GameSignature', signature);
        navigate('/'+gameType)
    }
    return (
        <div>
            <br/><br/>
            <div><b>Day Files</b></div>
            {
              dayFiles && dayFiles.map((item, index) => (
                <div>
                    <b>{item}</b> <button onClick={()=>{fileClick(item)}}>Глянуть</button>
                </div>
              ))
            }
        </div>
    )
}

export default DayReplays