import ApiUniversalServer from "./ApiUniversalServer"



const getOnlineGames = async () => {
    return await ApiUniversalServer.get(`/watch_games/games_online`, {})
  };


const getGamesReplays = async () => {
  return await ApiUniversalServer.get(`/watch_games/game_replays`, {})
};


const getDayReplays = async (scope, gameType,  day) => {
  return await ApiUniversalServer.post(`/watch_games/replays_day`, {Scope: scope, GameType: gameType, DateFolder: day})
};

  const UniversalServerService = {
    getOnlineGames,
    getGamesReplays,
    getDayReplays,
  };
  
  export default UniversalServerService;