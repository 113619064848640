import * as React from "react"

import { FunctionComponent, useEffect, useRef, useState } from "react"

import { useLoading } from "./AsyncLoading.tsx"
import {GodotInstance, GodotEngine, HasGodotInstance} from "./atoms.tsx"
import {useRecoilState,} from 'recoil';

export type ReactEngineProps = {
  engine: Engine
  width?: number
  height?: number
  params?: any
  resize?: boolean
  godotConfig: any
}

function toFailure(err) {
  var msg = err.message || err
  console.error(msg)
  return { msg, mode: "notice", initialized: true }
}

const ReactCanvas: FunctionComponent<ReactEngineProps> = ({
  engine,
  width = 480,
  height = 300,
  godotConfig,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [instance, setInstance] = useState<any>()
  const [loadingState, changeLoadingState] = useLoading()

  useEffect(() => {
    if (engine.isWebGLAvailable()) {
      changeLoadingState({ mode: "indeterminate" })
      setInstance(new engine(godotConfig))
    } else {
      changeLoadingState(toFailure("WebGL not available"))
    }
  }, [engine,])

  useEffect(() => {
    if (instance) {
      instance
        .startGame({
					'onProgress': function (current, total) {
						if (total > 0) {
							// statusProgressInner.style.width = current/total * 100 + '%';
							// setStatusMode('progress');
              changeLoadingState({ mode: "progress", percent: current / total })
							if (current === total) {
								// wait for progress bar animation
								setTimeout(() => {
                  changeLoadingState({ mode: "indeterminate" })
								}, 500);
							}
						} else {
              changeLoadingState({ mode: "indeterminate" })
						}
					},
				})
        .then(() => {
          changeLoadingState({ mode: "hidden", initialized: true })
          if (canvasRef.current) {
            canvasRef.current.style.position = "relative";
            canvasRef.current.style.width = "100%"//width+"px";
            canvasRef.current.style.height = "100%"//height+"px";
            console.log("SIZES", width, height)
          }
        })
        .catch(err => changeLoadingState(toFailure(err)))

      // instance.setProgressFunc((current, total) => {
      //   if (total > 0) {
      //     changeLoadingState({ mode: "progress", percent: current / total })
      //   } else {
      //     changeLoadingState({ mode: "indeterminate" })
      //   }
      // })
    }
  }, [instance, godotConfig, changeLoadingState])

  // useEffect(() => {
  //   if (instance) {
  //     instance.setCanvas(canvasRef.current)
  //   }
  // }, [instance, canvasRef.current])

  return (
    <canvas
      ref={canvasRef}
      id="canvas"
      style={{ display: loadingState.initializing ? "hidden" : "block" }}
    >
      HTML5 canvas appears to be unsupported in the current browser.
      <br />
      Please try updating or use a different browser.
    </canvas>
  )
}

export default ReactCanvas