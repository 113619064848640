import ApiUniversalServer from "./ApiUniversalServer"



const getLocalToken = () => {
    let token = localStorage.getItem('AbracadabraBOOM');
    if (token === null) {
      return null
    }
    return token;
  };

  const checkAuth = async () => {
    return await ApiUniversalServer.post(`/auth/login`, {})
  }


  const TokenService = {
    getLocalToken,
    checkAuth,
  };
  
  export default TokenService;