



// Common
export const apiPort = ":8091"
export const protocolAPI = "https://"

// Dev
//export const domainNameAPI = "dev.minicybersport.ru"

//export const universalServerAPI = "https://dev.minicybersport.ru:7001"

// Prod 
export const domainNameAPI = window.location.host == "dev.minicybersport.ru:3001" ? "dev.minicybersport.ru" : "admin.mnogokomnat.ru"

export const universalServerAPI =  window.location.host == "dev.minicybersport.ru:3001" ? "https://dev.minicybersport.ru:7001" : "https://universalgame.minicybersport.ru:7000"



