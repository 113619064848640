import ReactGodot from "../react-godot/GodotReact.tsx"






function Space1vs1v1() {
    const GODOT_CONFIG = {"args":[],"canvasResizePolicy": 2,"executable":"/games_content/Space1vs1v1/Space1vs1v1","experimentalVK":false,"fileSizes":{"Space1vs1v1.pck":4295248,"Space1vs1v1.wasm":13790961},"focusCanvas":true,"gdnativeLibs":[]};

    return (
    <div>
        <ReactGodot key={"godotplay"} godotConfig={GODOT_CONFIG}  resize={true} />
    </div>
    )
}


export default Space1vs1v1