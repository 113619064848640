import logo from './logo.svg';
import './App.css';
import Index from "./layout/Index";
import Layout from "./layout/Layout"
import {
  RecoilRoot
} from 'recoil';
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom"
import WatchOnline from "./universalserver/watch_online/WatchOnline"
import WatchReplays from "./universalserver/watch_replays/WatchReplays"
import DayReplays from "./universalserver/watch_replays/DayReplays"
import Space1vs1v1 from "./games/Space1vs1v1"
import SlyBalabama from "./games/SlyBalabama"



function App() {
  return (
    <RecoilRoot>
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
           <Route path='/' element={<Index/>}/>
           <Route path='/UniversalServerGamesOnline' element={<WatchOnline/>}/>  
           <Route path='/Space1vs1v1' element={<Space1vs1v1/>}/>  
           <Route path='/SlyBalabama' element={<SlyBalabama/>}/>
           <Route path='/UniversalServerGamesReplays' element={<WatchReplays/>}/>  
           <Route path='/ReplaysDay/:scope/:gameType/:day' element={<DayReplays/>}/>  

          </Route>
        </Routes>
      </BrowserRouter>
    </RecoilRoot>
  );
}

export default App;
