

import HeaderMenu from "./HeaderMenu"
import { Outlet } from "react-router-dom";
import {Authorized} from "./atoms"
import {useRecoilState,} from 'recoil';






const Layout = () => {

    const [authorized, setAuthorized] = useRecoilState(Authorized);


    return (
        <>
            {authorized && <HeaderMenu/>}
            <Outlet />
        </>
    )
}

export default Layout