import React, { useEffect, useState } from "react";

import UniversalServerService from "../../services/UniversalServerService";
import {SuccessMessage} from "../../constants";

import {redirect, useNavigate} from "react-router-dom"


function WatchOnline() {
    const [anyOpponentGames, setAnyOpponentGames] = useState([]);
    const [currentOpponentGames, setCurrentOpponentGames] = useState([]);

    const navigate = useNavigate();


    useEffect(()=> {
        UniversalServerService.getOnlineGames().then((response)=>{
            if (response.data.Success == SuccessMessage) {
                var anyOpponentGames = []
                for (var prop in response.data.Games.AnyOpponentGames) {
                    if (Object.prototype.hasOwnProperty.call(response.data.Games.AnyOpponentGames, prop)) {
                      var gameType = {
                       name: prop,
                       gamesConnected: [],
                       lastGameID: response.data.Games.AnyOpponentGames[prop].LastGameID,
                      }
                      var GameType = response.data.Games.AnyOpponentGames[prop];
                      for (var prop in GameType.GamesConnected) {
                        if (Object.prototype.hasOwnProperty.call(GameType.GamesConnected, prop)) {
                            gameType.gamesConnected.push(GameType.GamesConnected[prop])
                        }
                      }
                      anyOpponentGames.push(gameType)  
                    }
                }
                setAnyOpponentGames(anyOpponentGames)


                var currentOpponentGames = []
                for (var prop in response.data.Games.CurrentOpponentGames) {
                    if (Object.prototype.hasOwnProperty.call(response.data.Games.CurrentOpponentGames, prop)) {
                      var gameType = {
                       name: prop,
                       gamesConnected: [],
                       gamesFindConnection: [],
                       lastGameID: response.data.Games.CurrentOpponentGames[prop].LastGameID,
                      }
                      var GameType = response.data.Games.CurrentOpponentGames[prop];
                      for (var prop in GameType.GamesConnected) {
                        if (Object.prototype.hasOwnProperty.call(GameType.GamesConnected, prop)) {
                            gameType.gamesConnected.push(GameType.GamesConnected[prop])
                        }
                      }
                      for (var prop in GameType.GamesFindConnection) {
                        if (Object.prototype.hasOwnProperty.call(GameType.GamesFindConnection, prop)) {
                            gameType.gamesFindConnection.push(GameType.GamesFindConnection[prop])
                        }
                      }
                      currentOpponentGames.push(gameType)  
                    }
                }
                setCurrentOpponentGames(currentOpponentGames)
            }
        })
    }, [])

    const watchClick = (scopType, gameType, id)=>{
        var signature = "online|"+scopType+"|"+gameType+"|"+id
        localStorage.setItem('GameSignature', signature);
        localStorage.setItem('PlayersNicknames', "");
        navigate('/'+gameType)
    }
    const watchCurrentClick = (scopType, gameType, id, player1Name, player2Name)=>{
        var signature = "online|"+scopType+"|"+gameType+"|"+id
        localStorage.setItem('GameSignature', signature);
        localStorage.setItem('PlayersNicknames', player1Name+"|"+player2Name);
        navigate('/'+gameType)
    }
    
    // case "anyOpponent":
	// 	c.watchAnyOpponentGame(gameType, gameID)
	// case "currentOpponent":
    return (
        <div>
            <br/><br/>
            <div><b>CurrentOpponent</b></div>
            {
              currentOpponentGames.map((item, index) => (
                                    <div>
                                            GameType={item.name}<br/>
                                            GamesConnected<br/>
                                            {
                                                item.gamesConnected.map((game, index) => (
                                                    <div>
                                                        Id: {game.ID} <button onClick={()=>{watchCurrentClick("currentOpponent", item.name, game.ID, game.Player1.PlayerNickname, game.Player2.PlayerNickname)}}>watch</button><br/>
                                                        Player1: {game.Player1.PlayerNickname} {""+game.Player1.Ready}<br/>
                                                        Player2: {game.Player2.PlayerNickname} {""+game.Player2.Ready}<br/>
                                                        Started: {""+game.Started}<br/>
                                                    </div>
                                                ))
                                            }<br/>
                                            GamesFindConnection<br/>
                                            {
                                                item.gamesFindConnection.map((game, index) => (
                                                    <div>
                                                        Id: {game.ID} <button onClick={()=>{watchCurrentClick("currentOpponent", item.name, game.ID, game.Player1.PlayerNickname, game.Player2.PlayerNickname)}}>watch</button><br/>
                                                        Player1: {game.Player1.PlayerNickname} {""+game.Player1.Ready}<br/>
                                                        Player2: {game.Player2.PlayerNickname} {""+game.Player2.Ready}<br/>
                                                        Started: {""+game.Started}<br/>
                                                    </div>
                                                ))
                                            }
                                            LastGameID={item.lastGameID}
                                    </div>
              ))
            }
            <br/><br/><br/>
            <div><b>AnyOpponent</b></div>
            {
              anyOpponentGames.map((item, index) => (
                                    <div>
                                            GameType={item.name}
                                            GamesConnected<br/>
                                            {
                                                item.gamesConnected.map((game, index) => (
                                                    <div>
                                                        Id: {game.ID} <button onClick={()=>{watchClick("anyOpponent", item.name, game.ID)}}>watch</button><br/>
                                                        Player1: {""+game.Player1.Ready}<br/>
                                                        Player2: {""+game.Player2.Ready}<br/>
                                                        Started: {""+game.Started}<br/>
                                                    </div>
                                                ))
                                            }
                                            LastGameID={item.lastGameID}
                                    </div>
              ))
            }

        </div>
    )
}

export default WatchOnline