import TokenService from "../services/TokenService";
import {SuccessMessage} from "../constants";
import React, { useEffect, useState } from "react";
import {redirect, useNavigate} from "react-router-dom"
import HeaderMenu from "./HeaderMenu"

import {Authorized} from "./atoms"
import {useRecoilState,} from 'recoil';


function Index() {
    const [authorized, setAuthorized] = useRecoilState(Authorized);
    const navigate = useNavigate();

    useEffect(()=> {
        TokenService.checkAuth().then((response) => {
            if (response.data.Success == SuccessMessage) {
                setAuthorized(true)
            }
        })
    }, [])

    if (!authorized) {
        return (<div>Not authorized</div>)
    }
    return (
        <div>
        </div>
    )
}

export default Index