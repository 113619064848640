import React, { useEffect, useState } from "react";

import UniversalServerService from "../../services/UniversalServerService";
import {SuccessMessage} from "../../constants";

import {redirect, useNavigate} from "react-router-dom"









function WatchReplays() {
    const [anyOpponentReplayDays, setAnyOpponentReplayDays] = useState([]);
    const [currentOpponentReplayDays, setCurrentOpponentReplayDays] = useState([]);

    const navigate = useNavigate();

    useEffect(()=> {
        UniversalServerService.getGamesReplays().then((response)=>{
            if (response.data.Success == SuccessMessage) {
                setAnyOpponentReplayDays(response.data.Replays.AnyOpponent)
                setCurrentOpponentReplayDays(response.data.Replays.CurrentOpponent)
            }
        })
    }, [])

    const watchDay = (scopeType, gameType, dayFolder)=>{
        navigate("/ReplaysDay/"+scopeType+"/"+gameType +"/"+dayFolder)

    }
    return (
        <div>
            <br/><br/>
            <div><b>CurrentOpponent</b></div>
            {
              currentOpponentReplayDays && currentOpponentReplayDays.map((gameType, index) => (
                <div>
                    {gameType.Name}<br/>
                    {
                        gameType.Days && gameType.Days .map((item, index) => (
                            <div>
                                    {item.DayFolder} <b>({item.CountOfReplays})</b> <button onClick={()=>{watchDay("CurrentOpponent", gameType.Name, item.DayFolder)}}>Глянуть</button>
                            </div>
                        ))
                    }
                </div>
              ))
            }
            <br/><br/>
            <div><b>AnyOpponent</b></div>
            {
              anyOpponentReplayDays && anyOpponentReplayDays.map((gameType, index) => (
                <div>
                    {gameType.Name}<br/>
                    {
                        gameType.Days && gameType.Days .map((item, index) => (
                            <div>
                                    {item.DayFolder} <b>({item.CountOfReplays})</b> <button onClick={()=>{watchDay("AnyOpponent", gameType.Name, item.DayFolder)}}>Глянуть</button>
                            </div>
                        ))
                    }
                </div>
              ))
            }
        </div>
    )
}

export default WatchReplays