import {redirect, useNavigate} from "react-router-dom"


function HeaderMenu() {
    const navigate = useNavigate();


    const menuItemStyle = {
        padding: "20px",
    }

    const toUniversalServerGamesOnline = () => {
        navigate('/UniversalServerGamesOnline')
    }
    const toUniversalServerGamesReplays = ()=>{
        navigate('/UniversalServerGamesReplays')
    }
    // const toAllRooms = () => {
    //     navigate('/rooms')
    // }
    // const toTalks = () => {
    //     navigate('/talks')
    // }
    // const toLivekit = () => {
    //     navigate('/livekit')
    // }
    // const toOrders = () => {
    //     navigate('/orders/last')
    // }
    // const toUserReports = () => {
    //     navigate('/reports/last')
    // }       
    return (
        <div>
            <span style={menuItemStyle} onClick={toUniversalServerGamesOnline}>ИгрыОнлайн</span>|
            <span style={menuItemStyle} onClick={toUniversalServerGamesReplays}>Реплеи</span>|

        </div>
    )
}

// <span style={menuItemStyle} onClick={toAllRooms}>Все комнаты</span>|
// <span style={menuItemStyle} onClick={toTalks}>Talks</span>|
// <span style={menuItemStyle} onClick={toLivekit}>Livekit</span>|
// <span style={menuItemStyle} onClick={toOrders}>Orders</span>|
// <span style={menuItemStyle} onClick={toUserReports}>Reports</span>

export default HeaderMenu