/**
 * @function ReactGodot
 */

import "./styles.css"

import * as React from "react"

import { FunctionComponent, useEffect, useRef, useState } from "react"

import AsyncLoading from "./AsyncLoading.tsx"
import ReactCanvas from "./ReactCanvas.tsx"
import {useRecoilState,} from 'recoil';
import {GodotEngine} from "./atoms.tsx"

const useScript = (url, engine, onLoad) => {
  useEffect(() => {
    if (engine != null) {
      console.log("ASASAS engine is NOT NULL")
      return
    }
    const script = document.createElement("script")

    script.src = url
    script.async = true
    script.onload = onLoad

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [url])
}

export type ReactGodotProps = {
  resize?: boolean
  width?: number
  height?: number
  params?: any
  godotConfig: any
}

const ReactGodot: FunctionComponent<ReactGodotProps> = props => {
  const scriptUrl = "/games_content/godot_start_script.js"
  const { resize = false, width, height, params, godotConfig } = props
  const outerRef = useRef<HTMLDivElement>(null)
  const [engine, setEngine] = useState(null)
  const [dimensions, setDimensions] = useState([width, height])



  useEffect(() => {
    if (engine != null) {
      console.log("ASASAS engine is NOT NULL")
      return
    }
    const script = document.createElement("script")

    script.src = scriptUrl
    script.async = true
    script.onload = () => {
      const scope = window as any
      //if (engine == null) {
        console.log("ASASAS engine is NULL---")
        setEngine(() => scope.Engine)
      // } else {
      //   console.log("ASASAS engine is NOTNOTNOTNOTNOT NULL")
  
      // }
    }

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [scriptUrl])



  useEffect(() => {
    if (resize && outerRef.current) {
      setDimensions([
        outerRef.current.clientWidth,
        outerRef.current.clientHeight
      ])
    }
  }, [resize, outerRef.current])

  return (
    <div id="wrap" ref={outerRef}>
      <AsyncLoading>
        {engine && (
          <ReactCanvas 
            engine={engine}          
            width={dimensions[0]}
            height={dimensions[1]}
            params={params}
            godotConfig={godotConfig}
          />
        )}
      </AsyncLoading>
    </div>
  )
}
// engine={engine}
export default ReactGodot