import axios from "axios";
import TokenService from "./TokenService";
import {universalServerAPI} from "../environment"
import {useNavigate,} from "react-router-dom"

const instance = axios.create({
  baseURL: universalServerAPI,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalToken();
    if (token) {
      // config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      config.headers["x-access-token"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {

    const originalConfig = err.config;
    if (originalConfig.url !== "/auth/loginPassword" && originalConfig.url !== "/auth/register" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
      }
      if (err.response.status === 401) {
       // document.location.href = "/Error";
      }
    }

    return Promise.reject(err);
  }
);

export default instance;